import React from "react";
import "../Theme/ToggleButton.css";
import SunDarkIcon from "../Images/sun-darkmode.png";
import MoonLightIcon from "../Images/moon-lightmode.png";

function ToggleButton(props) {
  const setDarkMode = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
    props.setTheme(true);
  };

  const setLightMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
    props.setTheme(false);
  };

  const selectedTheme = localStorage.getItem("selectedTheme");
  const isLightTheme = selectedTheme === "light";

  React.useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme");

    if (selectedTheme === "dark") {
      setDarkMode();
    } else {
      setLightMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTheme = () => {
    if (isLightTheme) {
      setDarkMode();
    } else {
      setLightMode();
    }
  };
  return (
    <div>
      <div className="theme-button" onClick={toggleTheme}>
        {isLightTheme ? (
          <img src={MoonLightIcon} alt="Sun Icon" className="Darkicon" />
        ) : (
          <img src={SunDarkIcon} alt="Moon Icon" className="Lighticon" />
        )}
      </div>
    </div>
  );
}

export default ToggleButton;
