import React from "react";
import DynamicTextField from "../../Common/TextField/TextField";
import "./Settings.css";
import Button from "../../Common/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";

function CompanyProfile() {
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone Number must be only digits")
      .min(10, "Phone Number must be at least 10 digits")
      .required("Phone Number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    companyName: Yup.string().required("Company Name is required"),
  });
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      password: "",
      companyName: "",
      companyType: "",
      tax: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      console.log("Company Profile", formik.values);
    },
  });

  const handleProfileUpdate = () => {
    if (
      formik.values.fullName &&
      formik.values.email &&
      formik.values.phoneNumber &&
      formik.values.password &&
      formik.values.companyName
    ) {
      console.log("Company Profile", formik.values);
    } else {
      formik.handleSubmit();
    }
  };
  return (
    <div>
      <h2 className="header-text">Company Profile</h2>
      <div className="fields-container">
        <DynamicTextField
          label="Full Name"
          name="fullName"
          placeholder="Full Name"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          error={formik.errors.fullName}
          errorMessage={
            formik.errors.fullName &&
            formik.touched.fullName &&
            formik.errors.fullName
          }
          required={true}
        />
        <DynamicTextField
          label="Work Email"
          name="email"
          placeholder="Work Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          errorMessage={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
          required={true}
        />
        <DynamicTextField
          label="Phone Number"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.errors.phoneNumber}
          errorMessage={
            formik.errors.phoneNumber &&
            formik.touched.phoneNumber &&
            formik.errors.phoneNumber
          }
          required={true}
        />
        <DynamicTextField
          label="Password"
          name="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          errorMessage={
            formik.errors.password &&
            formik.touched.password &&
            formik.errors.password
          }
          required={true}
        />
        <DynamicTextField
          label="Company Name"
          name="companyName"
          placeholder="Company Name"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          error={formik.errors.companyName}
          errorMessage={
            formik.errors.companyName &&
            formik.touched.companyName &&
            formik.errors.companyName
          }
          required={true}
        />
        <DynamicTextField
          label="Company Type"
          name="companyType"
          placeholder="Company Type"
          value={formik.values.companyType}
          onChange={formik.handleChange}
        />
        <DynamicTextField
          label="Tax"
          name="tax"
          placeholder="Tax"
          value={formik.values.tax}
          onChange={formik.handleChange}
        />
        <DynamicTextField
          label="Address"
          name="address"
          placeholder="Address"
          value={formik.values.address}
          onChange={formik.handleChange}
        />
      </div>
      <div className="button-container">
        <Button label="Save" event={handleProfileUpdate} />
      </div>
    </div>
  );
}

export default CompanyProfile;
