import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const getTimeDifference = (lastIndexed) => {
  const currentTime = new Date();
  const lastIndexTime = new Date(lastIndexed);
  const timeDifference = currentTime - lastIndexTime;

  const minuteDifference = Math.floor(timeDifference / (1000 * 60));
  const hourDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (hourDifference >= 1) {
    return `${hourDifference} hour(s) ago`;
  } else if (minuteDifference >= 1) {
    return `${minuteDifference} minute(s) ago`;
  } else {
    return "Just now";
  }
};

const countDocumentsInFolder = (folderName, AllUploadedFiles) => {
  const filesInFolder = AllUploadedFiles.filter(
    (file) => file.folder === folderName
  );
  return filesInFolder.length;
};

const countTotalFilesInFolder = (folder) => {
  let totalFiles = folder.files.length;

  for (const subFolder of folder.subFolders) {
    totalFiles += countTotalFilesInFolder(subFolder);
  }

  return totalFiles;
};
const displayFileName = (fileName) => {
  const maxLength = 25;
  if (fileName.length >= maxLength) {
    const extensionIndex = fileName.lastIndexOf(".");
    const extension = fileName.substring(extensionIndex);
    const start = fileName.substring(0, 10);
    const end = fileName.substring(extensionIndex - 5, extensionIndex);
    return `${start}...${end}${extension}`;
  }
  return fileName;
};
export {
  getTimeDifference,
  countDocumentsInFolder,
  countTotalFilesInFolder,
  displayFileName,
};
