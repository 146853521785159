import React, { useState } from "react";
import "./Settings.css";
import CompanyProfilePage from "./CompanyProfile";
import IntegrationPage from "./Integration";
import NotificationsPage from "./Notifications";
import SubscriptionsPage from "./Subscriptions";
import Documentation from "./Documentation";
import { ReactComponent as CompanyProfile } from "../Images/Company-profile-icon.svg";
import { ReactComponent as IntegrationIcon } from "../Images/Integration-icon.svg";
import { ReactComponent as NotificationsIcon } from "../Images/settings-notification.svg";
import { ReactComponent as SubscriptionsIcon } from "../Images/Subscriptions-icon.svg";
import { ReactComponent as ApiDocIcon } from "../Images/api-doc.svg";

function Settings() {
  const [activeTab, setActiveTab] = useState("CompanyProfile");

  const renderContent = () => {
    switch (activeTab) {
      case "CompanyProfile":
        return <CompanyProfilePage />;
      case "Integration":
        return <IntegrationPage setActiveTab={setActiveTab} />;
      case "Subscriptions":
        return <SubscriptionsPage />;
      case "Notifications":
        return <NotificationsPage />;
      case "Documentation":
        return <Documentation />;
      default:
        return <CompanyProfilePage />;
    }
  };

  return (
    <>
      <div className="setting-header-container">
        <h1>Settings</h1>
      </div>
      <div className="container">
        <div className="sidebar">
          <div
            className={`tab ${activeTab === "CompanyProfile" ? "active" : ""}`}
            onClick={() => setActiveTab("CompanyProfile")}
          >
            <CompanyProfile /> Company Profile
          </div>
          <div
            className={`tab ${activeTab === "Integration" ? "active" : ""}`}
            onClick={() => setActiveTab("Integration")}
          >
            <IntegrationIcon />
            Integration
          </div>
          <div
            className={`tab ${activeTab === "Subscriptions" ? "active" : ""}`}
            onClick={() => setActiveTab("Subscriptions")}
          >
            <SubscriptionsIcon /> Subscriptions
          </div>
          <div
            className={`tab ${activeTab === "Notifications" ? "active" : ""}`}
            onClick={() => setActiveTab("Notifications")}
          >
            <NotificationsIcon />
            Notifications
          </div>
          <div
            className={`tab ${activeTab === "Documentation" ? "active" : ""}`}
            onClick={() => setActiveTab("Documentation")}
          >
            <ApiDocIcon />
            Documentation
          </div>
        </div>
        <div
          className={
            activeTab === "Subscriptions"
              ? "subscription-content"
              : "content-area"
          }
        >
          {renderContent()}
        </div>
      </div>
    </>
  );
}

export default Settings;
