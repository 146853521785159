import React, { useState, useEffect } from "react";
import DynamicChart from "../../Common/Charts/Charts";
import "./Dashboard.css";
import VectorImg from "../Images/men-office-desk.png";
import CreditCard from "../Images/credit-card.png";
import CrownIcon from "../Images/premium-crown.png";
import UserIcon from "../Images/subscriber-icon.svg";
import PlanIcon from "../Images/plan-icon.svg";
import RenewalIcon from "../Images/Renewal-icon.svg";
import PrizeIcon from "../Images/prize-icon.svg";
import optionDatas from "../../assets/json/StaticData.json";
function Dashboard() {
  const { OptionData = {} } = optionDatas || {};
  const {
    MonthlyParsingData = [],
    Subscriberslist = [],
    DashboardReminders = [],
    parsingSuccessData = [],
  } = OptionData || {};
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const endDate = new Date("2024-11-25");
    const today = new Date();
    const timeDiff = endDate - today;
    setDaysLeft(Math.ceil(timeDiff / (1000 * 60 * 60 * 24)));
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header-container">
        <h1>Dashboard</h1>
      </div>
      <div className="message-container">
        <img src={CreditCard} alt="CreditCard" className="credit-card-img" />
        <span>
          Your current plan is expiring in <span>{daysLeft}</span> day, so
          please renew your account.
        </span>
      </div>
      <div className="user-plan-outer">
        <div className="user-panel-outer">
          <h2>Hello,NandhaKumar Sri</h2>
          <h5>Welcome back to Resume Parser </h5>
        </div>
        <div className="current-plan-container">
          <div className="current-plan-content">
            <div className="text-container">
              <h3>Current Plan</h3>
              <div className="plan-text-container">
                <span>Basic Plan</span>

                <img src={CrownIcon} alt="CrownIcon" className="grown-img" />
              </div>
              <p>
                Your plan ends in <span>{daysLeft}</span> days
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img" />
          </div>
        </div>
      </div>
      <div className="chart-section">
        <div className="year-chart-container">
          <h4 className="chart-header">Resumes Parsed Each Month</h4>
          <p className="chart-description">
            Track the number of resumes parsed each month to monitor parsing
            trends and workload.
          </p>
          <DynamicChart
            type="bar"
            data={MonthlyParsingData}
            width={800}
            height={421}
          />
        </div>
        <div className="success-chart-container">
          <h4 className="chart-header">Parsing Success Rate</h4>
          <p className="chart-description">
            Breakdown of successful vs. unsuccessful resume parsing attempts to
            gauge parsing accuracy.
          </p>
          <DynamicChart
            type="pie"
            data={parsingSuccessData}
            width={400}
            height={400}
            colors={["#10ab10a6", "#ff0000b5"]}
          />
        </div>
      </div>
      <div className="details-list-container">
        <div className="reminder-list">
          <h3>Reminders</h3>
          <div className="reminder-cards">
            {DashboardReminders.map((reminder, index) => (
              <div
                key={index}
                className={`reminder-card ${reminder.type.toLowerCase()}`}
              >
                <img
                  src={RenewalIcon}
                  alt={`${reminder.type} icon`}
                  className="icon"
                />
                <div className="reminder-info">
                  <span className="title">{reminder.title}</span>
                  <span className="due-date">Due: {reminder.dueDate}</span>
                  <span className={`type ${reminder.type.toLowerCase()}`}>
                    {reminder.type}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="subscriber-list">
          <h3>Subscription List</h3>
          <ul className="subscriber-cards">
            {Subscriberslist.map((subscriber, index) => (
              <li key={index} className="subscriber-card">
                <div className="subscriber-detail">
                  <img src={UserIcon} alt="User Icon" className="icon" />
                  <div className="subscriber-info">
                    <span className="label">Subscriber Name</span>
                    <span className="value">{subscriber.name}</span>
                  </div>
                </div>
                <div className="subscriber-detail">
                  <img src={PlanIcon} alt="Tariff Icon" className="icon" />
                  <div className="subscriber-info">
                    <span className="label">Current Plan</span>
                    <span className="value">{subscriber.tariff}</span>
                  </div>
                </div>
                <div className="subscriber-detail">
                  <img src={PrizeIcon} alt="Cost Icon" className="icon" />
                  <div className="subscriber-info">
                    <span className="label">Plan Price</span>
                    <span className="value">{subscriber.tariffCast}</span>
                  </div>
                </div>
                <div className="subscriber-detail">
                  <img src={RenewalIcon} alt="Renewal Icon" className="icon" />
                  <div className="subscriber-info">
                    <span className="label">Renewal Date</span>
                    <span className="value">{subscriber.renewalDate}</span>
                  </div>
                </div>
                {/* <div className="subscriber-detail">
                  <img
                    src={
                      subscriber.status === "Active"
                        ? ActiveStatusIcon
                        : InctiveStatusIcon
                    }
                    alt="renewalDate Icon"
                    className="icon"
                  />
                  <div className="subscriber-info">
                    <span className="label">Plan Status</span>
                    <span className="value">{subscriber.status}</span>
                  </div>
                </div> */}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
