import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import LoginPage from "../Component/Login-Page/LoginPage";
import CandidateFilesUpload from "../Component/File-Upload/FileUploadPage";
import FileUploadPage from "../Component/File-Upload/FileUploadContainer";
import Errorpage from "../Component/Errorpage/ErrorPage";
import { AuthRoute } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";
import DynamicProps from "../Component/DynamicProps/DynamicProps";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Members from "../Component/Members/Members";
import Settings from "../Component/Settings-Pages/Settings";
import ManageAccount from "../Component/Manage-Account/ManageAccount";
import Dashboard from "../Component/Dashboard/Dashboard";
import { ScrollToTop } from "../Common/Common-Functions/CommonFunction";

export default function AppRouter() {
  const {
    LoginUserEmail,
    setLoginUserEmail,
    Logout,
    setLogout,
    folders,
    setFolders,
    indexingStatus,
    setIndexingStatus,
    Theme,
    setTheme,
    expandedFolders,
    setExpandedFolders,
    isSearching,
    setIsSearching,
    searchResults,
    setSearchResults,
    singleUploadFiles,
    setSingleUploadFiles,
    isLoading,
    setIsLoading,
  } = DynamicProps();

  const dynamicProps = {
    LoginUserEmail,
    setLoginUserEmail,
    Logout,
    setLogout,
    folders,
    setFolders,
    indexingStatus,
    setIndexingStatus,
    Theme,
    setTheme,
    expandedFolders,
    setExpandedFolders,
    isSearching,
    setIsSearching,
    searchResults,
    setSearchResults,
    singleUploadFiles,
    setSingleUploadFiles,
    isLoading,
    setIsLoading,
  };

  const token = localStorage.getItem("LoginUserEmail", LoginUserEmail);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/" element={<LoginPage {...dynamicProps} />} />
        </Route>

        <Route
          element={
            <>
              <Header {...dynamicProps} />
              <PrivateRoute />
              <Footer {...dynamicProps} />
            </>
          }
        >
          <Route path="/dashboard" element={<Dashboard {...dynamicProps} />} />
          <Route
            path="/parsed-data-list"
            element={<CandidateFilesUpload {...dynamicProps} />}
          />
          <Route path="/members-list" element={<Members {...dynamicProps} />} />
          <Route
            path="/file-upload"
            element={<FileUploadPage {...dynamicProps} />}
          />
          <Route path="/settings" element={<Settings {...dynamicProps} />} />
          <Route
            path="/manage-account"
            element={<ManageAccount {...dynamicProps} />}
          />
        </Route>

        <Route path="*" element={token ? <Errorpage /> : <Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
