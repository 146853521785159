import React from "react";
import "../Footer/Footer.css";
import VyLabsLightLogo from "../Images/Vylabs Logo - DarkMode.png";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="copyright-container">
          <p className="copyright">
            {`Copyright ©${currentYear}. All rights reserved. Powered by `}
            <img
              src={VyLabsLightLogo}
              alt="Vy Labs Logo"
              className="footer-logo"
            />
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
