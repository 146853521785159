import React, { useState, useEffect } from "react";
import "../File-Upload/FileUploadPage.css";
import { useNavigate } from "react-router-dom";
import downloadIcon from "../Images/download-icon.png";
import jsonIcon from "../Images/json-view-icon.png";
import trash from "../Images/trash.svg";
import Table from "../../Common/Table/Table";
import DynamicSkeleton from "../../Common/Skeleton/Skeleton";
import filterIcon from "../Images/filter.svg";
import uploadIcon from "../Images/upload.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import noDataFound from "../Images/noDataFound.jpg";
import ContentDialog from "../../Common/Content-Dialog/ContentDialog";
import { displayFileName } from "../../Common/Common-Functions/CommonFunction";
import Button from "../../Common/Button/Button";
import ErrorIcon from "@mui/icons-material/Error";
import LinearProgress from "@mui/material/LinearProgress";
import CustomTooltip from "../../Common/Tooltip/Tooltip";

function CandidateFilesUpload(props) {
  const { singleUploadFiles, isLoading, setIsLoading } = props || {};
  const [fileUploadProgress, setFileUploadProgress] = useState({});
  const [fileUploadStatus, setFileUploadStatus] = useState({});
  const [displayFilesContainer, setDisplayFilesContainer] = useState(true);
  const [fileStatusTimer, setFileStatusTimer] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    fileType: [],
    sizeRange: [0, Infinity],
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const navigate = useNavigate();

  const getFileTypeDisplay = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return "PDF";
      case "application/msword":
        return "DOC";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "DOCX";
      default:
        return "Unknown";
    }
  };

  const confirmDeleteRow = (number) => {
    const updatedData = tableData.filter((row) => row.number !== number);
    setTableData(updatedData);
    setFilteredData(updatedData);
    setRowToDelete(null);
  };

  const simulateUploadProgress = (fileName, shouldFail) => {
    const interval = setInterval(() => {
      setFileUploadProgress((prev) => {
        const currentProgress = prev[fileName] || 0;

        if (currentProgress < 100) {
          const updatedProgress = currentProgress + 5;
          localStorage.setItem(fileName, updatedProgress);
          return { ...prev, [fileName]: updatedProgress };
        } else {
          clearInterval(interval);
          localStorage.setItem(fileName, 100);
          setFileUploadStatus((prev) => ({
            ...prev,
            [fileName]: shouldFail ? "error" : "success",
          }));
          return { ...prev, [fileName]: 100 };
        }
      });
    }, 100);
  };
  useEffect(() => {
    singleUploadFiles.forEach((file) => {
      const shouldFail = Math.random() < 0.2;
      simulateUploadProgress(file.name, shouldFail);
    });
  }, [singleUploadFiles]);

  useEffect(() => {
    Object.keys(fileUploadStatus).forEach((fileName) => {
      if (fileUploadStatus[fileName] === "success") {
        const timer = setTimeout(() => {
          setFileStatusTimer((prev) => ({
            ...prev,
            [fileName]: "removed",
          }));
        }, 3000);
        return () => clearTimeout(timer);
      }
    });
  }, [fileUploadStatus]);
  useEffect(() => {
    const storedProgress = {};
    const randomErrorIndex = Math.floor(
      Math.random() * singleUploadFiles.length
    );
    singleUploadFiles.forEach((file, index) => {
      const storedValue = localStorage.getItem(file.name);
      const shouldFail = index === randomErrorIndex;

      if (storedValue) {
        storedProgress[file.name] = parseInt(storedValue, 10);
        if (storedValue === "100") {
          setFileUploadStatus((prev) => ({
            ...prev,
            [file.name]: shouldFail ? "error" : "success",
          }));
        }
      } else {
        storedProgress[file.name] = 0;
        simulateUploadProgress(file.name, shouldFail);
      }
    });

    setFileUploadProgress(storedProgress);
  }, [singleUploadFiles]);

  useEffect(() => {
    const updatedTableData = singleUploadFiles.reduce((acc, file, index) => {
      const normalizedFileName = displayFileName(file.name)
        .toLowerCase()
        .trim();

      const fileExists = acc.some(
        (item) =>
          item.name.toLowerCase().trim() === normalizedFileName &&
          item.size === (file.size / 1024).toFixed(2) + " KB"
      );

      if (!fileExists) {
        acc.push({
          number: acc.length + 1,
          name: displayFileName(file.name),
          type: getFileTypeDisplay(file.type),
          dateTime: new Date().toLocaleString(),
          parsedTime: "N/A",
          size: (file.size / 1024).toFixed(2) + " KB",
        });
      }

      return acc;
    }, []);

    setTableData(updatedTableData);
    setFilteredData(updatedTableData);
  }, [singleUploadFiles]);

  const applyFilters = () => {
    let filtered = tableData;

    if (filters.fileType.length > 0) {
      filtered = filtered.filter((item) =>
        filters.fileType.includes(item.type)
      );
    }

    if (filters.sizeRange[0] > 0 || filters.sizeRange[1] < Infinity) {
      filtered = filtered.filter((item) => {
        const fileSize = parseFloat(item.size);
        return (
          fileSize >= filters.sizeRange[0] && fileSize <= filters.sizeRange[1]
        );
      });
    }

    setFilteredData(filtered);
  };

  const handleApplyFilters = () => {
    applyFilters();
    setIsFilterModalOpen(false);
  };
  console.log("table data", filteredData);

  const columns = [
    {
      name: "#",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.number
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          <CustomTooltip title={row.name} arrow>
            <span className="table-span">{row.name}</span>
          </CustomTooltip>
        ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Type",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.type
        ),
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.dateTime
        ),
      sortable: true,
    },
    {
      name: "Parsed Time",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.parsedTime
        ),
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.size
        ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) =>
        isLoading ? (
          <DynamicSkeleton
            variant="rectangular"
            dimensions={{ width: 150, height: 24 }}
          />
        ) : rowToDelete === row.number ? (
          <div className="delete-outer">
            <div
              style={{
                backgroundColor: "#f2f2ff",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                padding: "8px 0px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                height: "-webkit-fill-available",
              }}
            >
              <p>Are you sure you want to delete this parsed data?</p>
              <button
                className="yes-button"
                onClick={() => confirmDeleteRow(row.number)}
              >
                Yes
              </button>
              <button
                className="no-button"
                onClick={() => setRowToDelete(null)}
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomTooltip title="Download" arrow>
              <img
                src={downloadIcon}
                alt="Action 1"
                style={{ width: "20px", cursor: "pointer", marginLeft: "10px" }}
              />
            </CustomTooltip>
            <CustomTooltip title="JSON" arrow>
              <img
                src={jsonIcon}
                alt="Action 2"
                style={{ width: "20px", cursor: "pointer", marginLeft: "10px" }}
              />
            </CustomTooltip>
            <CustomTooltip title="Delete Action" arrow>
              <img
                src={trash}
                alt="Action 3"
                style={{ width: "20px", cursor: "pointer", marginLeft: "10px" }}
                onClick={() => setRowToDelete(row.number)}
              />
            </CustomTooltip>
          </div>
        ),
      width: "200px",
      right: true,
    },
  ];

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(loadingTimeout);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    let timer;
    Object.keys(fileStatusTimer).forEach((fileName, index) => {
      if (
        fileStatusTimer[fileName] === "removed" &&
        fileUploadStatus[fileName] !== "error"
      ) {
        timer = setTimeout(() => {
          setFileStatusTimer((prev) => ({
            ...prev,
            [fileName]: "deleted",
          }));
        }, index * 500);
      }
    });
    return () => clearTimeout(timer);
  }, [fileStatusTimer, fileUploadStatus]);

  return (
    <>
      <div className="main-resume-container">
        <div className="resume-upper-container">
          <div className="resume-header-container">
            <h1 style={{ margin: "0" }}>Resume Parser</h1>
          </div>
          <div className="resume-button-container">
            <div className="resume-buttons">
              <Button
                className="api"
                label="API Integration"
                style={{ cursor: "pointer" }}
              />
              <Button
                className="filter"
                label="Filter"
                iconSrc={filterIcon}
                event={() => setIsFilterModalOpen(true)}
                style={{ cursor: "pointer" }}
              />
              <Button
                className="upload"
                label="Upload"
                iconSrc={uploadIcon}
                event={() => navigate("/file-upload")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {filteredData.length > 0 ? (
          <div className="table-container">
            <Table
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="300px"
            />
          </div>
        ) : (
          <div className="no-data-message">
            <img src={noDataFound} alt="" style={{ width: "500px" }} />
            <h3
              style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
            >
              Upload files to view the data
            </h3>
          </div>
        )}
        {singleUploadFiles.length > 0 && displayFilesContainer && (
          <div className="uploaded-files-notification-container">
            <div className="uploaded-files-header">
              Uploaded Files
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={() => setDisplayFilesContainer(false)}
              />
            </div>
            <ul className="uploaded-files-list">
              {singleUploadFiles.map((file, index) => {
                const status = fileUploadStatus[file.name];
                const isDeleted = fileStatusTimer[file.name] === "deleted";

                return (
                  <li
                    key={index}
                    style={{ display: isDeleted ? "none" : "block" }}
                  >
                    <div className="file-list-container">
                      <span style={{ marginRight: "10px" }}>
                        {displayFileName(file.name)}
                      </span>
                      <div className="progress-div">
                        {status === "success" ? (
                          <CheckCircleIcon
                            style={{
                              color: "green",
                              fontSize: "20px",
                              marginLeft: "8px",
                            }}
                          />
                        ) : status === "error" ? (
                          <ErrorIcon
                            style={{
                              color: "red",
                              fontSize: "20px",
                              marginLeft: "8px",
                            }}
                          />
                        ) : (
                          <LinearProgress
                            variant="determinate"
                            value={fileUploadProgress[file.name] || 0}
                            style={{ width: "100%" }}
                          />
                        )}
                        <span style={{ marginLeft: "8px" }}>
                          {status === "success"
                            ? "Uploaded"
                            : status === "error"
                            ? "Parsing Failed"
                            : "Uploading..."}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <ContentDialog
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        title="Filter Options"
        submitButtonLabel="Apply"
        cancelButtonLabel="Cancel"
        event={handleApplyFilters}
      >
        <div className="filters">
          <div className="filter-checkboxes">
            <h4>File Type</h4>
            <label>
              <input
                type="checkbox"
                value="PDF"
                checked={filters.fileType.includes("PDF")}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    fileType: e.target.checked
                      ? [...prev.fileType, "PDF"]
                      : prev.fileType.filter((type) => type !== "PDF"),
                  }))
                }
              />
              PDF
            </label>
            <label>
              <input
                type="checkbox"
                value="DOC"
                checked={filters.fileType.includes("DOC")}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    fileType: e.target.checked
                      ? [...prev.fileType, "DOC"]
                      : prev.fileType.filter((type) => type !== "DOC"),
                  }))
                }
              />
              DOC
            </label>
            <label>
              <input
                type="checkbox"
                value="DOCX"
                checked={filters.fileType.includes("DOCX")}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    fileType: e.target.checked
                      ? [...prev.fileType, "DOCX"]
                      : prev.fileType.filter((type) => type !== "DOCX"),
                  }))
                }
              />
              DOCX
            </label>
          </div>
          <div className="filter-checkboxes">
            <h4>File Size Range</h4>
            <label>
              <input
                type="checkbox"
                checked={
                  filters.sizeRange[0] === 0 && filters.sizeRange[1] === 50
                }
                onChange={() =>
                  setFilters((prev) => ({
                    ...prev,
                    sizeRange: [0, 50],
                  }))
                }
              />
              0-50 KB
            </label>
            <label>
              <input
                type="checkbox"
                checked={
                  filters.sizeRange[0] === 50 && filters.sizeRange[1] === 200
                }
                onChange={() =>
                  setFilters((prev) => ({
                    ...prev,
                    sizeRange: [50, 200],
                  }))
                }
              />
              50-200 KB
            </label>
            <label>
              <input
                type="checkbox"
                checked={
                  filters.sizeRange[0] === 200 &&
                  filters.sizeRange[1] === Infinity
                }
                onChange={() =>
                  setFilters((prev) => ({
                    ...prev,
                    sizeRange: [200, Infinity],
                  }))
                }
              />
              200+ KB
            </label>
          </div>
        </div>
      </ContentDialog>
    </>
  );
}

export default CandidateFilesUpload;
