import React, { useState } from "react";
import "./Settings.css";
import Button from "../../Common/Button/Button";
import { ReactComponent as JavaScriptIcon } from "../../Component/Images/javascript-icon.svg";
import { ReactComponent as JavaIcon } from "../../Component/Images/java-icon.svg";
import { ReactComponent as PhpIcon } from "../../Component/Images/php-icon.svg";
import { ReactComponent as PythonIcon } from "../../Component/Images/python-icon.svg";
import { ReactComponent as CIcon } from "../../Component/Images/C#-icon.svg";

function Documentation() {
  const [activeTab, setActiveTab] = useState("Documentation");
  const languageIcons = {
    JavaScript: <JavaScriptIcon />,
    Python: <PythonIcon />,
    Java: <JavaIcon />,
    CSharp: <CIcon />,
    PHP: <PhpIcon />,
    cURL: <JavaScriptIcon />,
  };

  return (
    <>
      <h2 className="header-text">Documentation</h2>
      <div className="outer-select-container">
        <span
          className={`option-selector ${
            activeTab === "Documentation" ? "active-option-selector" : ""
          }`}
          onClick={() => setActiveTab("Documentation")}
        >
          API Documentation
        </span>
        <span
          className={`option-selector ${
            activeTab === "RequestSamples" ? "active-option-selector" : ""
          }`}
          onClick={() => setActiveTab("RequestSamples")}
        >
          Request Samples
        </span>
      </div>

      {activeTab === "Documentation" && (
        <div className="documentation-container">
          <section className="documentation-section">
            <h3>Step 1: Upload Files to S3 Bucket with Pre-signed URLs</h3>
            <p>
              Send a POST request to retrieve upload URLs: Use the{" "}
              <code>/files/get-upload-urls/</code> endpoint to obtain pre-signed
              URLs from the S3 bucket for each file you wish to upload. The API
              returns URLs that allow you to upload files directly to S3.
            </p>
            <h4>Example of a generated pre-signed URL:</h4>
            <pre className="code-block">
              https://vylabs-be-stage-bucket.s3.us-west-1.amazonaws.com/&lt;file-path&gt;?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=&lt;CREDENTIAL&gt;&X-Amz-Date=&lt;DATE&gt;&X-Amz-Expires=3599&X-Amz-SignedHeaders=host&x-id=PutObject&X-Amz-Signature=&lt;SIGNATURE&gt;
            </pre>
            <p>
              Upload the files: Use the generated pre-signed URLs to upload
              files directly to S3 via HTTP PUT requests. Ensure the files are
              in the correct format and meet any required S3 upload
              specifications.
            </p>
          </section>
          <section className="documentation-section">
            <h3>Step 2: Trigger File Processing</h3>
            <p>After files are successfully uploaded to S3:</p>
            <p>
              Send a POST request to initiate processing: Use the{" "}
              <code>/resumes/process-uploaded-file/</code> endpoint to start
              parsing and processing the uploaded files. Include any necessary
              file identifiers or metadata required by your backend to locate
              and process each file.
            </p>
          </section>
          <section className="documentation-section">
            <h3>
              Step 3: Establish WebSocket Connection for Real-Time Updates
            </h3>
            <p>
              To receive real-time updates on the status of each processed file,
              establish a WebSocket connection.
            </p>
            <pre className="code-block">
              {`const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';\nconst wsPort = window.location.hostname === 'localhost' ? ':90' : '';\nconst wsUrl = \`\${wsProtocol}\${window.location.hostname}\${wsPort}/ws?token=Bearer \${encodeURIComponent('<ACCESS_TOKEN>')}\`;`}
            </pre>
          </section>
          <section className="documentation-section">
            <h3>Step 4: Handle WebSocket Events and Parse Incoming Data</h3>
            <p>
              When the WebSocket connection is active, you will start receiving
              real-time updates about the file processing status. Handle these
              events as follows:
            </p>
            <pre className="code-block">
              {`socket.onmessage = (event) => {\n  const data = JSON.parse(event.data);\n  if (data.fileStatus === "success") {\n    console.log("File processed successfully:", data.fileName);\n  } else {\n    console.error("Error processing file:", data.errorMessage);\n  }\n};`}
            </pre>
          </section>
        </div>
      )}

      {activeTab === "RequestSamples" && (
        <div className="request-samples-container">
          <h3 className="section-title">
            Resume Parser API - Request & Response Samples
          </h3>
          <p className="intro-text">
            We plan to provide **Request Samples** and **Response Samples** for
            various programming languages, showing how to implement the Resume
            Parser API. You'll also see example JSON output generated after
            parsing is completed.
          </p>

          <div className="sample-cards">
            {["JavaScript", "Python", "Java", "CSharp", "PHP", "cURL"].map(
              (language) => (
                <div className="sample-card" key={language}>
                  <div className="sample-card-header">
                    <span className="language-icon">
                      {languageIcons[language]}
                    </span>
                    <h4>{language}</h4>
                  </div>
                  <div className="sample-card-content">
                    <p>
                      <strong>Request Sample:</strong> See how to set up API
                      calls, handle responses, and manage file uploads in{" "}
                      {language}.
                    </p>
                    <p>
                      <strong>Response Sample:</strong> Explore the expected
                      JSON output format after parsing.
                    </p>
                    <Button label={`View ${language} Samples`} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Documentation;
