import React, { useEffect, useState } from "react";
import "./LoginPage.css";
// import sideimg from "../Images/Rectangle-2.png";
import googlelogo from "../Images/flat-color-icons_google.png";
import microsoftlogo from "../Images/logos_microsoft-icon.png";
import { useFormik } from "formik";
import eyeopen from "../Images/eye-open.svg";
import eyeclose from "../Images/eyes-close.svg";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Common/Toast-SnackBar/ToastSnackbar";
import ToggleButton from "../Theme/ToggleButton";
import TypographyButton from "../../Common/Common-Functions/LoginButton";
import TextInput from "../../Common/TextField/TextField";
import VyLabsDarkLogo from "../Images/Vylabs Logo - Dark.png";
import Button from "../../Common/Button/Button";

function LoginPage(props) {
  const [typewriterText, setTypewriterText] = useState("");
  const textToType =
    "Vy Labs, established in 2023, is a research-driven enterprise focusing on developing AI-driven digital solutions for enterprises. Our team is led by Vishwajith Ramesh, PhD, the Head of R&D at Vy Ventures and a visiting scientist at UC San Diego Computer Science and Engineering.";
  const navigate = useNavigate();
  useEffect(() => {
    let currentText = "";
    let currentIndex = 0;
    const typingInterval = 50;

    const typewriterTimer = setInterval(() => {
      currentText += textToType[currentIndex];
      setTypewriterText(currentText);
      currentIndex++;
      if (currentIndex === textToType.length) {
        clearInterval(typewriterTimer);
      }
    }, typingInterval);

    return () => {
      clearInterval(typewriterTimer);
    };
  }, []);

  const microsoftLoginBtnProps = {
    image: microsoftlogo,
    mainClassName: "Login-with-Microsoft",
    btnClassName: "Microsoft-button",
    btnText: "Login With Microsoft",
  };
  const googleLoginBtnProps = {
    image: googlelogo,
    mainClassName: "Login-with-Google",
    btnClassName: "google-button",
    btnText: "Login With Google",
  };
  const microsoftSignupBtnProps = {
    image: microsoftlogo,
    mainClassName: "Login-with-Microsoft",
    btnClassName: " Microsoft-button",
    btnText: "Signup With Microsoft",
  };
  const googleSignupBtnProps = {
    image: googlelogo,
    mainClassName: "Login-with-Google",
    btnClassName: "google-button",
    btnText: "Signup With Google",
  };
  const { setLoginUserEmail, setTheme } = props || {};

  const [showPassword, setShowPassword] = React.useState(false);
  const [SignupPage, setSignupPage] = React.useState(false);

  const Googlelogin = () => {
    alert("Working On Process");
  };
  const handlesignUp = () => {
    setSignupPage(true);
    formik.resetForm();
  };
  const handlecancelsignUp = () => {
    setSignupPage(false);
    formik.resetForm();
  };
  const formik = useFormik({
    initialValues: {
      LoginEmail: "",
      LoginPassword: "",
      SignupCandidateName: "",
      SignupEmail: "",
      SignupPassword: "",
    },
    validationSchema: Yup.object({
      LoginEmail: Yup.string()
        .email("Invalid email address")
        .max(35, "Email must not exceed 25 characters")
        .required("Please Enter your Email"),
      LoginPassword: Yup.string()
        .required("Please Enter your password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .max(15, "Password must not exceed 25 characters"),
      SignupCandidateName: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Only letters and spaces are allowed")
        .min(3, "Username must be at least 3 characters")
        .max(20, "Username must not exceed 20 characters")
        .required("Please Enter your Fullname"),

      SignupEmail: Yup.string()
        .email("Invalid email address")
        .max(35, "Email must not exceed 25 characters")
        .required("Please Enter your Email"),

      SignupPassword: Yup.string()
        .required("Please Enter your password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .max(15, "Password must not exceed 25 characters"),
    }),
  });
  const GetsignUpValue = () => {
    formik.handleSubmit();
    if (
      formik.values.SignupEmail &&
      formik.values.SignupPassword &&
      formik.values.SignupCandidateName
    ) {
      Toast("success", "SignUp Successfully");
      console.log(
        "SignUp Page Values >>>>>",
        "SignupEmail :",
        formik.values.SignupEmail,
        "SignupPassword :",
        formik.values.SignupPassword,
        "SignupCandidateName :",
        formik.values.SignupCandidateName
      );
    } else {
      Toast("error", "Invalid Email or Password");
    }
  };
  const GetLoginValue = () => {
    formik.handleSubmit();
    if (
      formik.values.LoginEmail === "rp@vylabs.ai" &&
      formik.values.LoginPassword === "rp@2024"
    ) {
      setLoginUserEmail(formik.values.LoginEmail);
      localStorage.setItem("LoginUserEmail", formik.values.LoginEmail);
      console.log(
        "Login Page Values >>>>>",
        "Email :",
        formik.values.LoginEmail,
        ",",
        "Password :",
        formik.values.LoginPassword
      );
      navigate("/dashboard");
      Toast("success", "Login Successfully");
    } else {
      Toast("error", "Invalid Email or Password");
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <div className="login-page">
      <div className="head-text">
        <div
          className={`side-img ${
            SignupPage ? "signup-height" : "login-height"
          }`}
        >
          {/* <img src={sideimg} alt="no img"></img> */}
          <div className="text-on-image">
            <h1>Welcome</h1>

            <img src={VyLabsDarkLogo} alt="no img" className="side-logo" />
            <p className="side-content-text">{typewriterText}</p>
            <a
              href={"https://vylabs.ai/"}
              className="learn-more"
              target="blank"
            >
              Learn About Vylabs
            </a>
            <div className="Login-footer">
              <p className="copyright">
                {`Copyright ©${currentYear} Vy Labs Inc. All rights reserved.`}
              </p>
            </div>
          </div>
        </div>
      </div>
      {SignupPage ? (
        <div className="login-container">
          <div className="login-header">
            <div className="Toogle-button">
              <ToggleButton setTheme={setTheme} />
            </div>
            <h5>
              Already Have An Account?{" "}
              <span onClick={handlecancelsignUp}>Login</span>
            </h5>
          </div>
          <h2>Create your account</h2>
          <div className="input-container">
            <h4>Full Name</h4>
            <TextInput
              type="text"
              placeholder="name"
              name="SignupCandidateName"
              value={formik.values.SignupCandidateName}
              onChange={formik.handleChange}
            />
            {formik.touched.SignupCandidateName &&
            formik.errors.SignupCandidateName ? (
              <div className="error-message">
                {formik.errors.SignupCandidateName}
              </div>
            ) : null}
            <h4>Email</h4>
            <TextInput
              type="text"
              placeholder="Email"
              name="SignupEmail"
              value={formik.values.SignupEmail}
              onChange={formik.handleChange}
              maxLength="50"
            />
            {formik.touched.SignupEmail && formik.errors.SignupEmail ? (
              <div className="error-message">{formik.errors.SignupEmail}</div>
            ) : null}
            <h4>Password</h4>
            <div className="password-div">
              <TextInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="SignupPassword"
                value={formik.values.SignupPassword}
                onChange={formik.handleChange}
              />
              <span
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <img src={eyeopen} alt="no img" />
                ) : (
                  <img src={eyeclose} alt="no img" />
                )}
              </span>
            </div>
            {formik.touched.SignupPassword && formik.errors.SignupPassword ? (
              <div className="error-message">
                {formik.errors.SignupPassword}
              </div>
            ) : null}
          </div>

          <div className="login-options">
            {/* <button
              className="login-button"
              onClick={GetsignUpValue}
              type="submit"
            >
              Create account
            </button> */}
            <Button
              label="Create account"
              event={GetsignUpValue}
              backgroundColor="linear-gradient(147deg, #2b8fff 0%, #e510ff 100%)"
              width="40%"
            />
            <div className="horizontal-section-outer">
              <p>Or </p>
            </div>

            <div className="login-buttons">
              <TypographyButton
                {...googleSignupBtnProps}
                onClick={Googlelogin}
              />

              <TypographyButton
                {...microsoftSignupBtnProps}
                onClick={Googlelogin}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="login-container">
          <div className="login-header">
            <div className="Toogle-button">
              <ToggleButton setTheme={setTheme} />
            </div>

            <h5>
              New User? <span onClick={handlesignUp}>sign up free</span>
            </h5>
          </div>
          <h2>Login</h2>
          <div className="input-container">
            <h4>Email</h4>
            <TextInput
              type="text"
              placeholder="Email"
              name="LoginEmail"
              value={formik.values.LoginEmail}
              onChange={formik.handleChange}
            />
            {formik.touched.LoginEmail && formik.errors.LoginEmail ? (
              <div className="error-message">{formik.errors.LoginEmail}</div>
            ) : null}
            <h4>Password</h4>
            <div className="password-div">
              <TextInput
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="LoginPassword"
                value={formik.values.LoginPassword}
                onChange={formik.handleChange}
              />
              <span
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <img src={eyeopen} alt="no img" />
                ) : (
                  <img src={eyeclose} alt="no img" />
                )}
              </span>
            </div>
            {formik.touched.LoginPassword && formik.errors.LoginPassword ? (
              <div className="error-message">{formik.errors.LoginPassword}</div>
            ) : null}
          </div>

          <div className="login-options">
            {/* <button
              className="login-button"
              onClick={GetLoginValue}
              type="submit"
            >
              login
              </button> */}
            <Button
              label="Login"
              event={GetLoginValue}
              backgroundColor="linear-gradient(147deg, #2b8fff 0%, #e510ff 100%)"
              width="40%"
            />

            <div className="horizontal-section-outer">
              <p>Or </p>
            </div>

            <div className="login-buttons">
              <TypographyButton
                {...googleLoginBtnProps}
                onClick={Googlelogin}
              />
              <TypographyButton
                {...microsoftLoginBtnProps}
                onClick={Googlelogin}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
