import React, { useRef, useState } from "react";
import "../File-Upload/FileUploadPage.css";
import { Toast } from "../../Common/Toast-SnackBar/ToastSnackbar";
import { useNavigate } from "react-router-dom";
import DragandDropIcon from "../Images/drag&drop-icon.png";
import { displayFileName } from "../../Common/Common-Functions/CommonFunction";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../../Common/Button/Button";

const allowedFileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const isValidFileType = (file) => allowedFileTypes.includes(file.type);

const isDuplicateFile = (file, uploadedFiles) =>
  uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name);

function FileUploadContainer(props) {
  const { singleUploadFiles, setSingleUploadFiles, setIsLoading } = props || {};

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [, setUploadProgress] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [draggedFiles, setDraggedFiles] = useState([]);

  const navigate = useNavigate();
  const fileRef = useRef();

  const processFiles = (files) => {
    const invalidFiles = Array.from(files).filter(
      (file) => !isValidFileType(file)
    );
    const duplicateFiles = Array.from(files).filter(
      (file) => isValidFileType(file) && isDuplicateFile(file, uploadedFiles)
    );

    if (invalidFiles.length > 0) {
      Toast("error", "Invalid file type detected, please upload valid files.");
      return null;
    }

    if (duplicateFiles.length > 0) {
      Toast("error", "Duplicate files detected, please upload unique files.");
      return null;
    }

    return Array.from(files).filter(
      (file) => isValidFileType(file) && !isDuplicateFile(file, uploadedFiles)
    );
  };

  const handleFileChange = () => {
    const files = fileRef.current.files;
    const validFiles = processFiles(files);

    if (validFiles) {
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...validFiles.map((file) => ({
          number: prevFiles.length + 1,
          name: file.name,
        })),
      ]);
      setDraggedFiles(validFiles);
    }
  };

  const handleCancel = () => {
    navigate("/parsed-data-list");
  };

  const handleUpload = async () => {
    if (uploadedFiles.length === 0) {
      Toast("error", "Please add a file to upload.");
      return;
    }

    const filesToProcess =
      draggedFiles.length > 0
        ? draggedFiles
        : Array.from(fileRef.current.files);
    const validFiles = filesToProcess.filter(
      (file) =>
        isValidFileType(file) && !isDuplicateFile(file, singleUploadFiles)
    );

    if (validFiles.length === 0) {
      Toast("error", "Duplicate files detected, please upload valid files.");
      return;
    }

    validFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const fileObject = new File([event.target.result], file.name, {
          type: file.type,
        });

        setSingleUploadFiles((prevFiles) => [...prevFiles, fileObject]);

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 0,
        }));
      };
      reader.readAsArrayBuffer(file);
    });

    setIsLoading(true);
    setIsHeaderActive(true);
    navigate("/parsed-data-list");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    const validFiles = processFiles(droppedFiles);

    if (validFiles) {
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...validFiles.map((file) => ({
          number: prevFiles.length + 1,
          name: file.name,
        })),
      ]);
      setDraggedFiles(validFiles);
    }
  };

  const handleFileRemove = (index) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    setDraggedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };

  return (
    <div className={`file-upload-outer ${isHeaderActive ? "active" : ""}`}>
      {uploadedFiles.length > 0 && (
        <div className="uploaded-files-name-list">
          <h3>Resumes</h3>
          <ul>
            {uploadedFiles.map((file, index) => (
              <li key={index}>
                <span className="file-name">{displayFileName(file.name)}</span>
                <CancelIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleFileRemove(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="Files-upload-container">
        <h2>Upload Files</h2>
        <div
          className={`file-drop-container ${isDraggingOver ? "drag-over" : ""}`}
          onDragEnter={() => setIsDraggingOver(true)}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={() => setIsDraggingOver(false)}
          onDrop={handleDrop}
          onClick={() => fileRef.current.click()}
        >
          <label
            htmlFor="Files-upload"
            className="file-upload-label"
            onClick={(e) => e.stopPropagation()}
          >
            <img src={DragandDropIcon} alt="no img" />
            <p className="drag-drop">
              Drag and Drop Files (or) Folder here,
              <br />
              or <span className="choose-file">
                Click to Select the files
              </span>{" "}
              here
            </p>

            <input
              hidden={true}
              ref={fileRef}
              id="Files-upload"
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              multiple
            />
          </label>
        </div>
        <div className="modal-buttons">
          <Button
            label="Upload"
            backgroundColor="#6860ee"
            event={handleUpload}
          />
          <Button
            label="Cancel"
            backgroundColor="#F5F4F3"
            color="#7B7A7A"
            border="1px solid #ACACAC"
            event={handleCancel}
          />
        </div>
      </div>
    </div>
  );
}

export default FileUploadContainer;
