import React from "react";
import VyLabsDarkLogo from "../../Component/Images/Vylabs Logo - DarkMode.png";
import VyLabsLightLogo from "../../Component/Images/Vylabs Logo - DarkMode.png";

function VyBotLogo(props) {
  const { Theme = "", className = "", style = {} } = props;
  return (
    <div>
      {" "}
      <img
        src={Theme ? VyLabsDarkLogo : VyLabsLightLogo}
        alt="vy-bot"
        className={className}
        style={style}
      />
    </div>
  );
}

export default VyBotLogo;
