import React, { useState } from "react";
import "../Settings-Pages/Settings.css";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import placeholderIcon from "../Images/place-holder.svg";
import styled from "@emotion/styled";
import { Toast } from "../../Common/Toast-SnackBar/ToastSnackbar";
import "./ManageAccount.css";
import Button from "../../Common/Button/Button";
import { useFormik } from "formik";
import ProfileIcon from "../Images/profile-icon.png";
import EmailIcon from "../Images/email-icon.png";
import LocationIcon from "../Images/location-icon.png";
import PhoneIcon from "../Images/phone-number-icon.png";
import CompanyIcon from "../Images/company-icon.png";
import TextField from "../../Common/TextField/TextField";
import { ReactComponent as TabProfileIcon } from "../Images/profile-tab-icon.svg";
import { ReactComponent as ResetPasswordIcon } from "../Images/password-reset-icon.svg";
import { ReactComponent as EditProfileIcon } from "../Images/profile-edit-icon.svg";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

export const StyledUploadAvatar = styled(Avatar)({
  width: 35,
  height: 35,
  cursor: "pointer",
});

function ManageAccount(props) {
  const { LoginUserEmail } = props || {};
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const [submittedValues, setSubmittedValues] = useState({
    firstName: "Nandhakumar",
    lastName: "Sri",
  });
  const [avatarImage, setAvatarImage] = useState(null);
  const [storedImage, setStoredImage] = useState(
    localStorage.getItem("uploadedImage") || null
  );
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required.")
      .test(
        "differentNames",
        "First and Last Names cannot be the same.",
        function (value) {
          return value !== this.parent.lastName;
        }
      ),
    lastName: Yup.string()
      .required("Last Name is required.")
      .test(
        "differentNames",
        "First and Last Names cannot be the same.",
        function (value) {
          return value !== this.parent.firstName;
        }
      ),
    location: Yup.string().required("Location is required."),
    companyName: Yup.string().required("Company Name is required."),
    oldPassword: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .required("Old Password is required."),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .required("New Password is required."),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match with new password."
      )
      .required("Confirm Password is required."),
  });
  const formik = useFormik({
    initialValues: {
      firstName: submittedValues.firstName,
      lastName: submittedValues.lastName,
      email: "nandhakumar@gmail.com",
      phoneNumber: "6381068170",
      location: "Tamilnadu,India",
      companyName: "Vybog pvt",
      oldPassword: "rpfe@2024",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
  });

  const fullName = `${submittedValues.firstName} ${submittedValues.lastName}`;

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.size <= 1024 * 1024) {
        setAvatarImage(reader.result);
      } else {
        Toast("warning", "Please upload the image less than 1MB");
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileEdit = () => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = "image/*";
    inputElement.onchange = handleImageChange;
    inputElement.click();
  };

  const handleUploadImage = () => {
    if (avatarImage) {
      localStorage.setItem("uploadedImage", avatarImage);
      setStoredImage(avatarImage);
      Toast("success", "Image uploaded successfully");
    } else {
      Toast("warning", "Please select an image to upload");
    }
  };
  const toogleHeader = () => {
    switch (activeTab) {
      case "profile":
        return "Personal Information";
      case "edit-profile":
        return "Edit Information";
      case "reset-password":
        return "Reset Password";

      default:
        return;
    }
  };

  const handleProfileUpdate = () => {
    if (
      formik.values.firstName &&
      formik.values.lastName &&
      formik.values.companyName &&
      formik.values.phoneNumber &&
      formik.values.location
    ) {
      setSubmittedValues({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
      });
      setActiveTab("profile");
    } else {
      formik.handleSubmit();
    }
  };

  const handleResetPassword = () => {
    if (
      formik.values.oldPassword &&
      formik.values.newPassword &&
      formik.values.confirmPassword
    ) {
      localStorage.clear("LoginUserEmail", LoginUserEmail);
      navigate("/");
      Toast("success", "Password Changed successfully");
    } else {
      formik.handleSubmit();
    }
  };
  return (
    <>
      <div className="setting-header-container">
        <h1>Manage Account</h1>
      </div>
      <div className="account-overall-container">
        <div className="profile-update">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <StyledUploadAvatar className="edit-icon" src={placeholderIcon} />
            }
            onClick={handleProfileEdit}
          >
            <Avatar
              alt="Profileicon"
              src={storedImage || avatarImage}
              sx={{ width: 100, height: 100 }}
            />
          </Badge>
          <h3 className="account-name">{fullName}</h3>
          <div className="img-upload-button-container">
            <Button
              label="Upload Image"
              className="img-upload-button"
              event={handleUploadImage}
            />
          </div>
          <div className="img-info-container">
            <span>Image upload size should not exceed 1MB.</span>
          </div>
          <div className="tab-container">
            <div
              className={`account-tab ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={() => setActiveTab("profile")}
            >
              <TabProfileIcon /> Profile
            </div>
            <div
              className={`account-tab ${
                activeTab === "edit-profile" ? "active" : ""
              }`}
              onClick={() => setActiveTab("edit-profile")}
            >
              <EditProfileIcon /> Edit Profile
            </div>
            <div
              className={`account-tab ${
                activeTab === "reset-password" ? "active" : ""
              }`}
              onClick={() => setActiveTab("reset-password")}
            >
              <ResetPasswordIcon />
              Reset Password
            </div>
          </div>
        </div>

        <div className="account-field-contaiiner">
          <div className="inner-header">
            <div className="header-content">
              <h2>{toogleHeader()}</h2>
              <span className="header-span">
                {activeTab === "profile"
                  ? "Manage and edit your personal information via Edit Profile."
                  : ""}
              </span>
            </div>
          </div>
          {activeTab === "profile" && (
            <div className="field-outer-container">
              <div className="inner-container">
                <img
                  src={ProfileIcon}
                  alt="ProfileIcon"
                  className="profile-icon"
                />
                <h3>Name</h3>
                <span className="value-span">{fullName}</span>
              </div>
              <div className="inner-container">
                <img
                  src={EmailIcon}
                  alt="ProfileIcon"
                  className="profile-icon"
                />
                <h3>Email</h3>
                <span className="value-span">{formik.values.email}</span>
              </div>
              <div className="inner-container">
                <img
                  src={PhoneIcon}
                  alt="ProfileIcon"
                  className="profile-icon"
                />
                <h3>Phone Number</h3>
                <span className="value-span">{formik.values.phoneNumber}</span>
              </div>
              <div className="inner-container">
                <img
                  src={LocationIcon}
                  alt="ProfileIcon"
                  className="profile-icon"
                />
                <h3>Country Region</h3>
                <span className="value-span">{formik.values.location}</span>
              </div>
              <div className="inner-container">
                <img
                  src={CompanyIcon}
                  alt="ProfileIcon"
                  className="profile-icon"
                />
                <h3>Company Name</h3>
                <span className="value-span">{formik.values.companyName}</span>
              </div>
            </div>
          )}
          {activeTab === "edit-profile" && (
            <>
              <div className="edit-account-outer">
                <TextField
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.errors.firstName}
                  errorMessage={
                    formik.errors.firstName &&
                    formik.touched.firstName &&
                    formik.errors.firstName
                  }
                  required={true}
                />
                <TextField
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.errors.lastName}
                  errorClassName="error-message"
                  errorMessage={
                    formik.errors.lastName &&
                    formik.touched.lastName &&
                    formik.errors.lastName
                  }
                  required={true}
                />
                <TextField
                  label="Company Name"
                  placeholder="Company Name"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.errors.companyName}
                  errorMessage={
                    formik.errors.companyName &&
                    formik.touched.companyName &&
                    formik.errors.companyName
                  }
                  required={true}
                />
                <TextField
                  label="Location"
                  placeholder="Location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={formik.errors.location}
                  errorMessage={
                    formik.errors.location &&
                    formik.touched.location &&
                    formik.errors.location
                  }
                  required={true}
                />
              </div>
              <div className="account-button-container">
                <Button label="Update" event={handleProfileUpdate} />
              </div>
            </>
          )}
          {activeTab === "reset-password" && (
            <>
              <div className="edit-account-outer">
                <TextField
                  type="password"
                  label="Old Password"
                  name="oldPassword"
                  placeholder="Old Password"
                  showPasswordToggle={true}
                  onChange={formik.handleChange}
                  value={formik.values.oldPassword}
                  error={formik.errors.oldPassword}
                  errorMessage={
                    formik.errors.oldPassword &&
                    formik.touched.oldPassword &&
                    formik.errors.oldPassword
                  }
                  readOnly={true}
                  required={true}
                />

                <TextField
                  label="New Password"
                  placeholder="New Password"
                  type="password"
                  name={"newPassword"}
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  showPasswordToggle={true}
                  error={formik.errors.newPassword}
                  errorMessage={
                    formik.errors.newPassword &&
                    formik.touched.newPassword &&
                    formik.errors.newPassword
                  }
                  required={true}
                />
                <TextField
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  name={"confirmPassword"}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  showPasswordToggle={true}
                  error={formik.errors.confirmPassword}
                  errorMessage={
                    formik.errors.confirmPassword &&
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  required={true}
                />
              </div>
              <div className="account-button-container">
                <Button label="Reset" event={handleResetPassword} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ManageAccount;
