import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #efefef;
  position: sticky;
  top:0;
  z-index:999;
  background-color:white;
  padding: 5px;
  width:100%;
`;

export const LeftHeader = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
`;

export const RightHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 20px;
`;

export const ParserTab = styled.div`
  cursor: pointer;
`;

export const MembersTab = styled.div`
  cursor: pointer;
`;

export const ToggleButtonWrapper = styled.div``;

export const QuestionIcon = styled.div``;

export const ProfileMenu = styled.div`
  position: relative;
`;

export const StyledProfileMainOuterDiv = styled("div")({
  position: "absolute",
  top: "80px",
  backgroundColor: "rgba(255, 255, 255, 1)",
  zIndex: 999999,
  border: "1px solid rgba(207, 229, 255, 1)",
  borderRadius: "20px",
  padding: "20px",
});

export const StyledProfileMain = styled("div")({
  display: "flex",
  marginRight: "20px",
  alignItems: "center",
  gap: "25px",
  padding: "20px 0px",
  borderBottom: "1px solid rgba(207, 229, 255, 1)",
});

export const StyledProfileUpdateInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const StyledProfileOption = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
});

export const StyledProfileInfoInnerDiv = styled("div")({
  borderBottom: "1px solid rgba(207, 229, 255, 1)",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "10px 0px",
});

export const StyledPopupProfileAvatar = styled("div")({
  width: "30%",
});

export const StyledAvatarComponent = styled("div")({
  width: "40px",
  height: "40px",
  marginLeft: "5px",
  cursor: "pointer",
});

export const StyledEditIcon = styled("div")({
  height: "100%",
  width: "100%",
  bottom: "12px",
  right: "4px",
  cursor: "pointer",
});

export const StyledProfileDiv = styled("div")({
  position: "absolute",
  top: "80px",
  right:"0",
  backgroundColor: "rgba(255, 255, 255, 1)",
  zIndex: 999999,
  border: "1px solid #f2f2ff",
  borderRadius: "20px",
  padding: "20px",
});

export const StyledProfileAvatar = styled("img")({
  width: "30%",
});

export const StyledNavAvatar = styled(Avatar)({
  width: "40px",
  height: "40px",
  marginLeft: "5px",
  cursor: "pointer",
});
export const StyledSmallAvatar = styled(Avatar)({
  height: "100%",
  width: "100%",
  bottom: "12px",
  right: "4px",
  cursor: "pointer",
});
export const StyledProfileOptionDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
});
export const StyledProfileLogoutOptionDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  padding: "10px 0px",
});
export const StyledProfileInfoMainDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "10px",
});

export const StyledProfileOptionImage = styled("img")({
  cursor: "pointer",
  width: "8%",
});
export const StyledProfileOptionspan = styled("span")({
  fontSize: "14px",
  cursor: "pointer",
});

export const StyledProfileInnerdiv = styled("div")({
  display: "flex",
  marginRight: "20px",
  alignItems: "center",
  gap: "25px",
  padding: "20px 0px",
  borderBottom: "1px solid  #f2f2ff",
});
export const StyledProfileName = styled("span")({
  fontSize: "16px",
  fontWeight: "600",
});
export const StyledProfileEmail = styled("span")({
  fontSize: "12px",
  fontWeight: "500",
});


export const ToggleDiv = styled("div")({
    
});