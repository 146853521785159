import React from "react";
import "./PricingCard.css";
import { ReactComponent as YesIcon } from "../Images/Subscriptions-list-yes-icon.svg";
import { ReactComponent as NoIcon } from "../Images/Subscriptions-list-no-icon.svg";
const PricingCard = ({
  title,
  price,
  API_Calls_Limit,
  Parsing_Features,
  Bulk_Parsing,
  Hosting_Options,
  Customization,
  Button_Name,
  Currently_Activated,
}) => {
  return (
    <div className="PricingCard">
      <header>
        <p className="card-title">{title}</p>
        <h1 className="card-price">{price}</h1>
      </header>
      <div className="card-features">
        <div className="card-content">
          <YesIcon style={{ width: "20px", height: "20px" }} />{" "}
          {`API Call Limit : ${API_Calls_Limit}`}
        </div>
        {/* <div className="card-content">{`Parsing Features : ${Parsing_Features}`}</div> */}
        <div className="card-content">
          {Bulk_Parsing === "No" ? (
            <NoIcon style={{ width: "20px", height: "20px" }} />
          ) : (
            <YesIcon style={{ width: "20px", height: "20px" }} />
          )}

          {`Bulk Parsing : ${Bulk_Parsing}`}
        </div>
        <div className="card-content">
          {Hosting_Options === "No" ? (
            <NoIcon style={{ width: "20px", height: "20px" }} />
          ) : (
            <YesIcon style={{ width: "20px", height: "20px" }} />
          )}
          {`Hosting Options : ${Hosting_Options}`}
        </div>
        <div className="card-content">
          {Customization === "No" ? (
            <NoIcon style={{ width: "20px", height: "20px" }} />
          ) : (
            <YesIcon style={{ width: "20px", height: "20px" }} />
          )}
          {`Customization : ${Customization}`}
        </div>
      </div>
      {/* {Currently_Activated === "true" ? (
        <span>Current Plan</span>
      ) : (
        <button className="card-btn">{Button_Name}</button>
      )} */}
      <button className="card-btn">{Button_Name}</button>
    </div>
  );
};

export default PricingCard;
