import React, { useState } from "react";
import "./TextField.css";
import eyeopen from "../../Component/Images/eye-open.svg";
import eyeclose from "../../Component/Images/eyes-close.svg";
import styled from "@emotion/styled";

export const StyledErrorMessage = styled("div")({
  color: "red",
  fontSize: "14px",
  marginTop: "6px",
});

const DynamicTextField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  type = "text",
  required = false,
  showPasswordToggle = false,
  error = "",
  errorMessage = "",
  readOnly = false,
  style = {},
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="textfield-container">
      <label htmlFor={name} className="textfield-label">
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <div className="textfield-input-container">
        <input
          id={name}
          name={name}
          type={showPasswordToggle && isPasswordVisible ? "text" : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className="textfield-input"
          readOnly={readOnly}
          style={style}
        />
        {showPasswordToggle && type === "password" && (
          <img
            src={isPasswordVisible ? eyeopen : eyeclose}
            alt="Toggle Password Visibility"
            className="password-toggle-icon"
            onClick={handleTogglePasswordVisibility}
          />
        )}
      </div>

      {error && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  );
};

export default DynamicTextField;
