import React from "react";
import Tooltip from "@mui/material/Tooltip";

const CustomTooltip = ({ children, title, arrow = true }) => {
  return (
    <Tooltip 
      title={title}
      arrow={arrow}
      placement="bottom"
      enterDelay={200}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;