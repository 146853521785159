import React from "react";
import "./Settings.css";
import DynamicCheckBox from "../../Common/CheckBox/CheckBox";
import { useFormik } from "formik";

function Notifications() {
  const formik = useFormik({
    initialValues: {
      email: false,
      sms: false,
    },
    onChange: (values) => {
      console.log("Form Values:", values);
    },
  });

  const handleCheckboxChange = (field) => {
    formik.setValues((prevValues) => ({
      email: field === "email" ? !prevValues.email : false,
      sms: field === "sms" ? !prevValues.sms : false,
    }));
  };

  return (
    <div>
      <h2 className="header-text">Notifications</h2>
      <p>
        At VY Labs, we want to ensure you never miss important updates about
        your subscription, usage, billing, and service alerts. That's why we
        offer the flexibility to receive notifications through email and SMS, so
        you stay connected no matter where you are.
      </p>
      <p>
        By enabling notifications via email or SMS, you will get real-time
        alerts tailored to your preferences. Whether you need usage updates,
        payment reminders, or service announcements, you are always in control.
      </p>
      <h3 className="sub-header">Why Enable Email and SMS Notifications?</h3>
      <ul>
        <li className="notification-li">
          Get instant notifications about important events, such as subscription
          changes or API usage reaching your limit.
        </li>
        <li className="notification-li">
          Billing reminders ensure you avoid service interruptions by staying on
          top of upcoming payments or failed transactions.
        </li>
        <li className="notification-li">
          Receive immediate alerts about system downtime, maintenance schedules,
          or service disruptions.
        </li>
        <li className="notification-li">
          You can choose the notification channel that suits you best – email
          for detailed updates or SMS for urgent alerts on the go.
        </li>
        <li className="notification-li">
          Be proactive with usage alerts (e.g., when you’re close to your API
          call limits), so you can upgrade plans or adjust consumption as
          needed.
        </li>
        <li className="notification-li">
          Stay informed about account activity, password changes, or suspicious
          login attempts to keep your account secure.
        </li>
      </ul>

      <div className="checkbox-contanier">
        <DynamicCheckBox
          label="Email"
          checked={formik.values.email}
          onChange={() => handleCheckboxChange("email")}
          color="#9751fb"
        />
        <DynamicCheckBox
          label="SMS"
          checked={formik.values.sms}
          onChange={() => handleCheckboxChange("sms")}
          color="#9751fb"
        />
      </div>
    </div>
  );
}

export default Notifications;
