import React from "react";
import {
  PieChart,
  Pie,
  LineChart,
  Line,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";

function DynamicChart({
  type,
  data,
  color = "#8884d8",
  colors = [],
  width = "100%",
  height = 300,
}) {
  return (
    <div style={{ margin: "20px 0" }}>
      <ResponsiveContainer width={width} height={height}>
        {type === "pie" ? (
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill={color}
              label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length] || color}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        ) : type === "bar" ? (
          <BarChart data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill={color} />
          </BarChart>
        ) : (
          <LineChart data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke={color} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
}

export default DynamicChart;
