import { useState } from "react";
import PricingCard from "./PricingCard";
import "./Settings.css";

function Subscriptions() {
  const [selectMonthly, setSelectMonthly] = useState(true);

  return (
    <div className="PricingApp">
      <div className="app-container">
        <header>
          <h1 className="header-topic">Our Pricing Plan</h1>
          <div className="header-row">
            <p>Annually</p>
            <label className="price-switch">
              <input
                className="price-checkbox"
                onChange={() => {
                  setSelectMonthly((prev) => !prev);
                }}
                type="checkbox"
              />
              <div className="switch-slider"></div>
            </label>
            <p>Monthly</p>
          </div>
        </header>
        <div className="pricing-cards">
          <PricingCard
            title="15-Day Free Demo"
            price="$0"
            API_Calls_Limit="100/15 Days"
            Bulk_Parsing="No"
            Hosting_Options="No"
            Customization="No"
            Button_Name="Current Plan"
            Currently_Activated="true"
          />
          <PricingCard
            title="Basic"
            price={selectMonthly ? "$10" : "$100"}
            API_Calls_Limit="5,000/month"
            Parsing_Features="Essential Fields"
            Bulk_Parsing="No"
            Hosting_Options="Shared Cloud"
            Customization="No"
            Button_Name="Activate"
            Currently_Activated="false"
          />
          <PricingCard
            title="Premium"
            price={selectMonthly ? "$25" : "$275"}
            API_Calls_Limit="50,000/month"
            Parsing_Features="Advanced Fields"
            Bulk_Parsing="Yes (100 files)"
            Hosting_Options="Shared Cloud"
            Customization="Limited"
            Button_Name="Activate"
            Currently_Activated="false"
          />
          <PricingCard
            title="Enterprise"
            price={selectMonthly ? "$49" : "$499"}
            API_Calls_Limit="Unlimited/Negotiable"
            Parsing_Features="AI-Powered Parsing"
            Bulk_Parsing="Yes (Custom Limits)"
            Hosting_Options="Private/On-Premise"
            Customization="Fully Customizable"
            Button_Name="Activate"
            Currently_Activated="false"
          />
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
