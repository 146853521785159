import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../File-Upload/FileUploadPage.css";
import DynamicSkeleton from "../../Common/Skeleton/Skeleton";
import Table from "../../Common/Table/Table";
import filterIcon from "../Images/filter.svg";
import noDataFound from "../Images/noDataFound.jpg";
import DynamicTextField from "../../Common/TextField/TextField";
import ContentDialog from "../../Common/Content-Dialog/ContentDialog";
import Button from "../../Common/Button/Button";
import { ReactComponent as SendIcon } from "../Images/send.svg";
import exchange from "../Images/exchange.svg";
import exportIcon from "../Images/export.svg";
import trash from "../Images/trash.svg";

function Members(props) {
  const { isLoading, setIsLoading } = props || {};

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    invited: false,
    activated: false,
    pending: false,
  });
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleOpenInviteModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleApplyFilters = () => {
    setIsFilterModalOpen(false);
    const filtered = data.filter((member) => {
      if (selectedFilters.invited && member.status === "Invited") return true;
      if (selectedFilters.activated && member.status === "Activated")
        return true;
      if (selectedFilters.pending && member.status === "Pending") return true;
      return false;
    });
    setFilteredData(filtered.length ? filtered : data);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFilters((prev) => ({ ...prev, [name]: checked }));
  };

  const confirmDeleteRow = (number) => {
    const updatedData = data.filter((member) => member.number !== number);
    setData(updatedData);
    setFilteredData(updatedData);
    setRowToDelete(null);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required")
        .test("unique-email", "This email is already invited.", (value) => {
          const emailExists = data.some(
            (member) => member.name === value.split("@")[0]
          );
          return !emailExists;
        }),
    }),
    onSubmit: (values) => {
      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const newMember = {
        number: data.length + 1,
        name: values.email.split("@")[0],
        created: formatDate(new Date()),
        parsedCount: Math.floor(Math.random() * 100) + 1,
        memberID: `ID${Math.floor(Math.random() * 1000)}`,
        status: "Invited",
      };

      setIsLoading(true);

      setData((prevData) => {
        const updatedData = [...prevData, newMember];
        console.log("Current Members:", updatedData);
        return updatedData;
      });

      setFilteredData((prevData) => [...prevData, newMember]);

      setTimeout(() => {
        setIsLoading(false);
      }, 4000);

      handleCloseInviteModal();
      formik.resetForm();
    },
  });

  const columns = [
    {
      name: "#",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.number
        ),
    },
    {
      name: "Name",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.name
        ),
    },
    {
      name: "Created",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.created
        ),
    },
    {
      name: "Parsed Count",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.parsedCount
        ),
    },
    {
      name: "Member ID",
      selector: (row) =>
        isLoading ? (
          <DynamicSkeleton variant="text" dimensions={{ width: 150 }} />
        ) : (
          row.memberID
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        if (isLoading) {
          return <DynamicSkeleton variant="text" dimensions={{ width: 100 }} />;
        }

        const statusStyles = {
          Invited: {
            padding: "10px",
            backgroundColor: "#d1e7ff",
            color: "#004085",
          },
          Activated: {
            padding: "10px",
            backgroundColor: "#d4edda",
            color: "#155724",
          },
          Pending: {
            padding: "10px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
          },
        };
        const style = statusStyles[row.status] || {};
        return (
          <div
            style={{
              backgroundColor: style.backgroundColor,
              color: style.color,
              padding: "10px",
              borderRadius: "4px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {row.status}
          </div>
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => row.number,
      cell: (row) => {
        if (isLoading) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <DynamicSkeleton variant="text" dimensions={{ width: 120 }} />
            </div>
          );
        }

        return rowToDelete === row.number ? (
          <div className="delete-outer">
            <div
              style={{
                backgroundColor: "#f2f2ff",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                padding: "8px 0px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                height: "-webkit-fill-available",
              }}
            >
              <p>Are you sure you want to delete this member's detail?</p>
              <button
                className="yes-button"
                onClick={() => confirmDeleteRow(row.number)}
              >
                Yes
              </button>
              <button
                className="no-button"
                onClick={() => setRowToDelete(null)}
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <img
              src={exchange}
              alt="Action 1"
              style={{ width: "24px", cursor: "pointer", marginLeft: "10px" }}
            />
            <img
              src={exportIcon}
              alt="Action 2"
              style={{ width: "24px", cursor: "pointer", marginLeft: "10px" }}
            />
            <img
              src={trash}
              alt="Delete"
              style={{ width: "24px", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => setRowToDelete(row.number)}
            />
          </div>
        );
      },
      width: "200px",
      right: true,
    },
  ];
  return (
    <>
      <div className="main-resume-container">
        <div className="resume-upper-container">
          <div className="resume-header-container">
            <h1 style={{ margin: "0" }}>Members</h1>
            {/* <h4 style={{ margin: "0", fontWeight: "300" }}>
              Short description about the Members
            </h4> */}
          </div>
          <div className="resume-button-container">
            <div className="resume-buttons">
              <Button
                label="Invite member"
                event={handleOpenInviteModal}
                backgroundColor="#6860ee"
              />
              <Button
                label="Filter"
                event={handleOpenFilterModal}
                iconSrc={filterIcon}
                iconPosition="left"
                backgroundColor="#6860ee"
              />
            </div>
          </div>
        </div>
        {data.length > 0 ? (
          <div className="table-container">
            <Table
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight="300px"
            />
          </div>
        ) : (
         <div className="no-data-message">
            <img src={noDataFound} alt="" style={{ width: "500px" }} />
            <h3
              style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
            >
             Add members to view the data
            </h3>
          </div>
        )}
      </div>
      <ContentDialog
        isOpen={isModalOpen}
        title="Invite Member"
        onClose={handleCloseInviteModal}
        submitButtonLabel="Send"
        cancelButtonLabel="Cancel"
        event={formik.handleSubmit}
        submitButtonIcon={
          <SendIcon width={15} height={15} style={{ marginLeft: "10px" }} />
        }
      >
        <p style={{ marginBottom: "20px" }}>
          Invite a new member by entering their email address.
        </p>
        <div>
          <DynamicTextField
            type="email"
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email} 
            errorMessage={formik.errors.email}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        isOpen={isFilterModalOpen}
        title="Filter Members"
        onClose={handleCloseFilterModal}
        submitButtonLabel="Apply Filters"
        cancelButtonLabel="Cancel"
        event={handleApplyFilters}
      >
        <p style={{ marginBottom: "20px" }}>
          Select the status types to filter members.
        </p>
        <div className="filter-checkbox-container">
          <label>
            <input
              type="checkbox"
              name="invited"
              checked={selectedFilters.invited}
              onChange={handleCheckboxChange}
            />{" "}
            Invited
          </label>
          <label>
            <input
              type="checkbox"
              name="activated"
              checked={selectedFilters.activated}
              onChange={handleCheckboxChange}
            />{" "}
            Activated
          </label>
          <label>
            <input
              type="checkbox"
              name="pending"
              checked={selectedFilters.pending}
              onChange={handleCheckboxChange}
            />{" "}
            Pending
          </label>
        </div>
      </ContentDialog>
    </>
  );
}

export default Members;
