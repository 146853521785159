import React, { useRef, useState, useEffect } from "react";
// import ToggleButton from "../Theme/ToggleButton";
import VyBotLogo from "../../Common/Common-Functions/VyBotLogo.js";
import questionIcon from "../Images/circle.png";
import profileGIcon from "../Images/mdi_user.png";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Common/Toast-SnackBar/ToastSnackbar";
import "../File-Upload/FileUploadPage";
import * as StyledDOM from "./Header.jsx";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Profile from "../Images/Profile.svg";
import Logout from "../Images/Logout-icon.svg";
import EditIcon from "../Images/edit-icon.svg";
import SettingIcon from "../Images/Setting.svg";
import NotificationIcon from "../Images/Notification-icon.svg";
import "../../Common/common.css";
function Header(props) {
  const {
    setFolders,
    Theme,
    // setTheme,
    LoginUserEmail,
    setLogout,
    setSingleUploadFiles,
  } = props || {};
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear("LoginUserEmail", LoginUserEmail);
    navigate("/");
    Toast("success", "Logged out successfully");
    setLogout(false);
    setFolders([]);
    setSingleUploadFiles([]);
  };

  const [profileInfo, setProfileInfo] = useState(false);
  const profileRef = useRef(null);
  const profileIconRef = useRef(null);
  const ProfileImage = localStorage.getItem("avatarImage");
  const emailinfo = localStorage.getItem("email");

  const handleProfileInfo = () => {
    setProfileInfo((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        profileIconRef.current &&
        !profileIconRef.current.contains(event.target)
      ) {
        setProfileInfo(false);
      }
    };

    if (profileInfo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileInfo]);

  const handleSettingPage = () => {
    setProfileInfo(false);
    navigate("/settings");
  };
  const handleManageAccountNavigation = () => {
    setProfileInfo(false);
    navigate("/manage-account");
  };
  return (
    <>
      <StyledDOM.NavBar>
        <StyledDOM.LeftHeader>
          <VyBotLogo
            Theme={Theme}
            style={{ width: "8em", marginLeft: "10px" }}
          />
          <StyledDOM.ParserTab onClick={() => navigate("/dashboard")}>
            Dashboard
          </StyledDOM.ParserTab>
          <StyledDOM.ParserTab onClick={() => navigate("/parsed-data-list")}>
            Resume Parser
          </StyledDOM.ParserTab>
          <StyledDOM.MembersTab onClick={() => navigate("/members-list")}>
            Members
          </StyledDOM.MembersTab>
        </StyledDOM.LeftHeader>

        <StyledDOM.RightHeader className="right-header">
          {/* <ToggleButton setTheme={setTheme} /> */}
          <Badge badgeContent={17} color="secondary" max={30}>
            <img
              src={NotificationIcon}
              alt="NotificationIcon"
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginTop: "6px",
              }}
            />
          </Badge>
          <img
            src={questionIcon}
            alt="questionIcon"
            style={{ width: "30px", height: "30px", cursor: "pointer" }}
          />

          <img
            src={profileGIcon}
            alt="profileGIcon"
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
            onClick={handleProfileInfo}
            ref={profileIconRef}
          />
        </StyledDOM.RightHeader>
        {profileInfo && (
          <StyledDOM.StyledProfileDiv
            className="profile-mainouter-div"
            ref={profileRef}
          >
            <StyledDOM.StyledProfileInnerdiv className="profile-main">
              <Badge
                variant="circular"
                className="avatar component"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <StyledDOM.StyledSmallAvatar
                    className="edit-icon"
                    src={EditIcon}
                  />
                }
              >
                <Avatar
                  src={ProfileImage || null}
                  sx={{ width: 70, height: 70 }}
                  className="popup-profileAvatar"
                />
              </Badge>
              <StyledDOM.StyledProfileUpdateInfo className="profile-update-info">
                <StyledDOM.StyledProfileName>
                  Hi Roshan
                </StyledDOM.StyledProfileName>
                <StyledDOM.StyledProfileEmail
                  onClick={handleManageAccountNavigation}
                >
                  {emailinfo}
                </StyledDOM.StyledProfileEmail>
              </StyledDOM.StyledProfileUpdateInfo>
            </StyledDOM.StyledProfileInnerdiv>
            <StyledDOM.StyledProfileInfoMainDiv className="profile-info-main-div">
              <StyledDOM.StyledProfileInfoInnerDiv className="profile-info-inner-div">
                <StyledDOM.StyledProfileOption
                  className="profile-options-acount"
                  onClick={handleManageAccountNavigation}
                >
                  <StyledDOM.StyledProfileOptionImage
                    src={Profile}
                    alt="profileicon"
                    onClick={handleManageAccountNavigation}
                  />
                  <StyledDOM.StyledProfileOptionspan
                    onClick={handleManageAccountNavigation}
                  >
                    Manage Profile
                  </StyledDOM.StyledProfileOptionspan>
                </StyledDOM.StyledProfileOption>
                <StyledDOM.StyledProfileOptionDiv className="profile-options-acount">
                  <StyledDOM.StyledProfileOptionImage
                    src={SettingIcon}
                    alt="Settings"
                    onClick={handleSettingPage}
                  />
                  <StyledDOM.StyledProfileOptionspan
                    onClick={handleSettingPage}
                  >
                    Settings
                  </StyledDOM.StyledProfileOptionspan>
                </StyledDOM.StyledProfileOptionDiv>
              </StyledDOM.StyledProfileInfoInnerDiv>
              <StyledDOM.StyledProfileLogoutOptionDiv
                onClick={handleLogout}
                className="profile-options-acount"
              >
                <StyledDOM.StyledProfileOptionImage
                  src={Logout}
                  alt="Settings"
                />
                <StyledDOM.StyledProfileOptionspan>
                  Logout
                </StyledDOM.StyledProfileOptionspan>
              </StyledDOM.StyledProfileLogoutOptionDiv>
            </StyledDOM.StyledProfileInfoMainDiv>
          </StyledDOM.StyledProfileDiv>
        )}
      </StyledDOM.NavBar>
    </>
  );
}

export default Header;
