import React, { useState } from "react";
import { useFormik } from "formik";
import KeyIcon from "@mui/icons-material/Key";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import ArticleIcon from "@mui/icons-material/Article";
import DynamicTextField from "../../Common/TextField/TextField";
import Button from "../../Common/Button/Button";
import "./Settings.css";

function Integration({ setActiveTab }) {
  const [generated, setGenerated] = useState(false);

  const formik = useFormik({
    initialValues: {
      apiKey: "",
      secretKey: "",
      orgID: "",
    },
    onSubmit: (values) => {
      console.log("Integration Values:", values);
    },
  });

  const generateKeys = () => {
    const newApiKey = `api_${Math.random().toString(36).slice(2)}`;
    const newSecretKey = `sec_${Math.random().toString(36).slice(2)}`;

    formik.setFieldValue("apiKey", newApiKey);
    formik.setFieldValue("secretKey", newSecretKey);
    setGenerated(true);
  };

  return (
    <div className="accordion-container">
      <h2 className="header-text">Integration</h2>

      <div className="generate-container">
        <h3 className="sub-header">Generate your Resume Parser API Key</h3>
        <div className="fields-container">
          <DynamicTextField
            type="password"
            label="API Key"
            name="apiKey"
            placeholder="Enter API Key"
            value={formik.values.apiKey}
            onChange={formik.handleChange}
          />
          <DynamicTextField
            type="password"
            label="Secret Key"
            name="secretKey"
            placeholder="Enter Secret Key"
            value={formik.values.secretKey}
            onChange={formik.handleChange}
          />
        </div>
      </div>

      <div className="integration-button-container">
        <Button
          label="Generate New API Key"
          event={generateKeys}
          disabled={generated}
        />
      </div>

      <h3 className="sub-header">Details About Resume Parser API Key</h3>
      <ul className="api-details-list">
        <li>
          <span className="icon">
            <LockIcon />
          </span>
          <p>
            <strong>Purpose:</strong>{" "}
            <span>
              The API Key allows secure access to the Resume Parser, letting you
              integrate resume parsing features into your application.
            </span>
          </p>
        </li>
        <li>
          <span className="icon">
            <InfoIcon />
          </span>
          <p>
            <strong>Usage:</strong>{" "}
            <span>
              Use this API Key and Secret Key in your HTTP headers for
              authentication when making requests to the Resume Parser API.
            </span>
          </p>
        </li>
        <li>
          <span className="icon">
            <KeyIcon />
          </span>
          <p>
            <strong>One-Time Generation:</strong>{" "}
            <span>
              You can generate the API key only once. After you click{" "}
              <em>Generate New API Key</em>, this key will be your permanent
              access key. If you need a new key, please contact support.
            </span>
          </p>
        </li>
        <li>
          <span className="icon">
            <ArticleIcon />
          </span>
          <p>
            <strong>Documentation:</strong>{" "}
            <span>
              For step-by-step instructions on how to integrate the Resume
              Parser API into your application, visit the{" "}
              <span
                onClick={() => setActiveTab("Documentation")}
                className="active-link-span"
              >
                API Documentation
              </span>
              .
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Integration;
