import React from "react";
import { ToastContainer } from "react-toastify";
import AppRouter from "./Routes/AppRouter";

function App() {
  return (
    <>
      <ToastContainer />
      <AppRouter />
    </>
  );
}

export default App;
