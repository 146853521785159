import React from "react";

function Button(props) {
  const {
    image = "",
    mainClassName = "Login-with-Microsoft",
    btnClassName = "Microsoft-button",
    btnText = "",
    onClick,
  } = props;
  return (
    <div className={mainClassName} onClick={onClick}>
      <img src={image} alt="no img" />
      <button className={btnClassName}>{btnText}</button>
    </div>
  );
}

export default Button;
