import React from "react";
import "../Errorpage/ErrorPage.css";
import ErrorVideo from "../Images/Error-Video.mp4";
import { useNavigate } from "react-router-dom";
function ErrorPage() {
  const navigate = useNavigate();
  const handleExit = () => {
    navigate("/parsed-data-list");
  };
  return (
    <div className="video-container">
      <video className="video-2" autoPlay loop muted>
        <source src={ErrorVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="text-overlay">
        <h2>The Page you're looking for doesn't exist</h2>
        <button className="home-button" onClick={() => handleExit()}>
          Go To Home
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
