import React, { useState } from "react";

export default function DynamicProps() {
  const [LoginUserEmail, setLoginUserEmail] = useState("");
  const [singleUploadFiles, setSingleUploadFiles] = React.useState([]);
  const [Logout, setLogout] = useState(false);
  const [folders, setFolders] = useState([]);
  const [indexingStatus, setIndexingStatus] = useState([]);
  const [Theme, setTheme] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  return {
    LoginUserEmail,
    setLoginUserEmail,
    Logout,
    setLogout,
    folders,
    setFolders,
    indexingStatus,
    setIndexingStatus,
    Theme,
    setTheme,
    expandedFolders,
    setExpandedFolders,
    isSearching,
    setIsSearching,
    searchResults,
    setSearchResults,
    singleUploadFiles,
    setSingleUploadFiles,
    isLoading,
    setIsLoading,
  };
}
